import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { CallToAction } from "../../sections/CallToAction";
import { SellFaq } from "../../sections/SellFaq";
import { FeatureImageLeft } from "../../sections/FeatureImageLeft";
import { FeatureImageRight } from "../../sections/FeatureImageRight";
import { UpscalerBanner } from "../../sections/upscaler/UpscalerBanner";
import { UpscalerHow } from "../../sections/upscaler/UpscalerHow";
import { FeatureImageCfLeft } from "../../sections/FeatureImageCfLeft";
import { FeatureImageCfRight } from "../../sections/FeatureImageCfRight";
import { PatternDiscover } from "../../sections/pattern/PatternDiscover";

export const upscaler_faq_data = [
    {
        question: "What is the difference between Resize and Upscale?",
        answer: "When enlarging images, using Resize will result in a lower resolution photo than what you started with. You're essentially exchanging quality for size. Conversely, the Upscaler employs AI to not only enlarge but also enhance your image. It automatically increases the size of your photo while simultaneously improving clarity and reducing any pixelation or blurriness.",
    },
    {
        question: "How does the Image Upscaler work?",
        answer: "The Image Upscaler uses advanced AI algorithms to intelligently increase the resolution of an image while preserving its quality and details. It analyzes the image content and fills in missing information to create a higher-resolution version.",
    },
    {
        question:
            "What types of images can be upscaled using the Image Upscaler?",
        answer: "The Image Upscaler can upscale various all types of images including photographs, digital art, graphics, and more. It supports both JPEG and PNG image formats.",
    },
    {
        question:
            "Can the Image Upscaler enhance the resolution of low-resolution images?",
        answer: "Yes, the Image Upscaler can enhance the resolution of low-resolution images. It can extrapolate details and produce a higher-resolution version.",
    },
    {
        question:
            "How long does it take to remove the background from an image using the tool?",
        answer: "The processing time varies based on the image size, but typically, it only requires a few seconds to complete.",
    },
    /*{
        
        question: "What does 2x, 4x, and 8x upscaling mean?",
        answer: "The 2x upscaling doubles both the width and height of the original image, resulting in an image that is 4 times larger overall. Similarly, 4x upscaling increases the dimensions by 4 times in each direction, making the image 16 times larger, while 8x upscaling enlarges the image by 8 times in each direction, resulting in a 64 times larger image.",

    },*/
    {
        question: "What does 2x and 4x upscaling mean?",
        answer: "The 2x upscaling doubles both the width and height of the original image, resulting in an image that is 4 times larger overall. Similarly, 4x upscaling increases the dimensions by 4 times in each direction, making the image 16 times larger.",
    },
];

class UpscalerLanding extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Image Upscaler</title>
                    <meta
                        name="description"
                        content="AI-powered image upscaler to enhance and upscale images without losing quality, perfect for making images sharper, clearer and bigger."
                    />
                    <meta
                        name="keywords"
                        content="Image Upscaler, AI Image Upscaler, Image Upscaler APP, Upscale Image Online, Image Processing, AI Image Editing, Patternful, Patternful AI"
                    />
                </Helmet>
                <UpscalerBanner />
                <UpscalerHow />
                <FeatureImageCfLeft
                    beforeImage={"/images/upscaler/f1_before.png"}
                    afterImage={"/images/upscaler/f1_after.webp"}
                    title={"Enhance Sharpness and Highlight Details"}
                    description={
                        "The image upscaler intelligently increases image resolution while refining texture and clarity, making the image both larger and crisper. It is particularly useful for photographers, graphic designers, and anyone looking to improve the aesthetics of their images."
                    }
                />
                <FeatureImageCfRight
                    beforeImage={"/images/upscaler/f2_before.png"}
                    afterImage={"/images/upscaler/f2_after.webp"}
                    title={"Enlarge Size Without Losing Quality"}
                    description={
                        "The image upscaler enlarges the original image by filling in missing details and refining existing elements. Whether you need to prepare high-resolution images for print media, or improve the visual quality of web content, the image upscaler provides a seamless solution for enlaring image size with no noticeable loss in quality."
                    }
                />
                <CallToAction
                    title={"Achieve Unmatchable Image Quality in One Click"}
                    subtitle={
                        "Empowering photographers, inspiring anime creators, elevating product photography, with mind-blowing Image enhancement and upscaling."
                    }
                    link={"/image-upscaler/app"}
                    cta={"Get Started"}
                />
                <SellFaq data={upscaler_faq_data} />
                <PatternDiscover />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(UpscalerLanding));
