import { Link } from "react-router-dom";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PImg,
    PHighlightButton,
    PH3,
} from "../../theme/BaseTheme";

export const PatternDiscover = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-what">
                <PBox className="sell-what-container">
                    <PText className="sell-what-subtitle">
                        More in Patternful.ai
                    </PText>
                    <PH1 className="sell-what-title">Discover More</PH1>
                    <PBox className="sell-what-items-wrapper">
                        <Link
                            className="sell-what-item"
                            to={"/pattern-gallery/1"}
                        >
                            <PImg src={"/images/16.jpg"} alt="patternful ai" />
                            <PText>Pattern Gallery</PText>
                        </Link>
                        <Link
                            className="sell-what-item"
                            to={"/tee-design-gallery/1"}
                        >
                            <PImg
                                src={"/images/hshf.webp"}
                                alt="patternful ai"
                            />
                            <PText>Tee Design Gallery</PText>
                        </Link>
                        <Link className="sell-what-item" to={"/blog"}>
                            <PImg
                                src={"/images/present.webp"}
                                alt="patternful ai"
                            />
                            <PText>Blogs</PText>
                        </Link>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-what": {
        padding: "0 1.5rem",
        maxWidth: "80rem",
        margin: "4rem auto 10rem",
        overflow: "hidden",
        "@bp2": { marginTop: "4em" },
        paddingLeft: "4rem",
        paddingRight: "4rem",
    },
    "& .sell-what-container": {
        position: "relative",
    },
    "& .sell-what-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-what-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "2.5rem" },
    },
    "& .sell-what-text": {
        fontSize: "20px",
        lineHeight: "1.7",
        color: "#293745",
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "80px",
    },
    "& .sell-what-start-button": {
        backgroundColor: "$colors$quaternary",
        color: "#fff",
        margin: "20px",
    },
    "& .sell-what-items-wrapper": {
        display: "grid",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        maxWidth: "72rem",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "5rem",
        color: "rgb(17 24 39)",
        lineHeight: "1.5rem",
        fontSize: "0.875rem",
        gap: "2rem",
        "@bp2": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp4": {
            maxWidth: "72rem",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        },
    },
    "& .sell-what-item": {
        position: "relative",
        cursor: "pointer",
        "& p": {
            fontSize: "1.125rem",
            fontWeight: "600",
            letterSpacing: "0.125rem",
            backgroundColor: "#eeeeee",
            opacity: "0.85",
            textAlign: "center",
            width: `calc(100% + 0.125rem)`,
            position: "absolute",
            bottom: "-0.25rem",
            padding: "1rem 0",
            color: "rgb(17 24 39)",
            //marginLeft: "0.125rem",
        },
        "& img": {
            margin: "0.5rem 0",
            //borderRadius: "1rem",
            width: "100%",
            //border: "1px solid $colors$borderColor",
            boxShadow: "0 0 0 #0000, 0 0 0 #0000, 0 0 0 #0000",
        },
        "&:hover": {
            transform: "translate(0, -0.5rem)",
            transition: "0.5s ease",
        },
    },
};
