import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { MockupBanner } from "../../sections/mockup/MockupBanner";
import { MockupHow } from "../../sections/mockup/MockupHow";
import { MockupTypes } from "../../sections/mockup/MockupTypes";
import { FeatureImageLeft } from "../../sections/FeatureImageLeft";
import { FeatureImageRight } from "../../sections/FeatureImageRight";
import { SellFaq } from "../../sections/SellFaq";
import { CallToAction } from "../../sections/CallToAction";
import { PatternDiscover } from "../../sections/pattern/PatternDiscover";

const mockup_faq_data = [
    {
        question: "What is a mockup generator?",
        answer: "Mockup generator is a tool that lets you generate true-to-life images of your designs on products.",
    },
    {
        question: "What am I allowed to use the exported images for?",
        answer: "All generated images are royalty free for use in personal and commercial projects. You can modify the image to your liking so that it fits your project. You are not allowed, however, to resell or redistribute exported images as new mockups or images themselves.",
    },
    {
        question: "What about privacy? Are my designs safe?",
        answer: "Yes, your designs are safe with us. Patternful.ai prioritizes user data safety. Your designs are always private, proprietary, and they remain your creative property. All your uploaded images are automatically deleted from our servers after a few hours.",
    },
];

class MockupLanding extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Mockup Generator</title>
                    <meta
                        name="description"
                        content="Create stunning mockups online with one click. Forget about spending hours learning how to use Photoshop. With just one click, you can produce beautiful and near-perfect mockup images."
                    />
                    <meta
                        name="keywords"
                        content="Mockup Generator, Mockup Generator APP, Mockup Online Generator, Patternful, Patternful AI"
                    />
                </Helmet>
                <MockupBanner />
                <MockupHow />
                <MockupTypes />
                <FeatureImageLeft
                    image={"/images/mockup/speedup.webp"}
                    title={"Speed up the designing process"}
                    description={
                        "Get product mockups in one click, with ready-made templates. Know how your design will look on a product without having to place an order."
                    }
                />
                <FeatureImageRight
                    image={"/images/mockup/sales.webp"}
                    title={"Boost your sales"}
                    description={
                        "Give shoppers a feel of your products in various settings to help customers decide. Diversify your marketing content and use the mockups for your website, social media, and any other promotional materials."
                    }
                />
                <CallToAction
                    title={
                        "Start Generating Your Stunning Mockups for Free Today"
                    }
                    subtitle={
                        "Ready to showcase your product and elevate your projects and business with professional mockups? Sign up and generate for free."
                    }
                    link={"/mockup/app"}
                    cta={"Get Started"}
                />
                <SellFaq data={mockup_faq_data} />
                <PatternDiscover />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(MockupLanding));
