import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { SellFaq } from "../../sections/SellFaq";
import { FeatureImageLeft } from "../../sections/FeatureImageLeft";
import { FeatureImageRight } from "../../sections/FeatureImageRight";
import { CallToAction } from "../../sections/CallToAction";
import { ObjectRemoverBanner } from "../../sections/object_remover/ObjectRemoverBanner";
import { ObjectRemoverHow } from "../../sections/object_remover/ObjectRemoverHow";
import { PatternDiscover } from "../../sections/pattern/PatternDiscover";

export const object_remover_faq_data = [
    {
        question: "How to remove unwanted objects from my photos?",
        answer: "You can remove unwanted objects easily in the following 3 steps - 1. Upload the image that you want to edit. 2. Use the brush tool to highlight the object you want to remove and click 'Remove'. 3. You can now download the generated image.",
    },
    {
        question: "What types of objects can be removed?",
        answer: "Our AI object remover caters to various editing needs and scenarios. With a few clicks, you can effectively remove people, text, watermarks, logos, stickers, and more.",
    },
    {
        question:
            "Is my data and privacy safe when using the AI object remover?",
        answer: "Yes, your images are safe with us. Patternful.ai prioritizes user data safety. Your images are always private, proprietary, and they remain your property. All your uploaded images are automatically deleted from our servers after a few hours.",
    },
    {
        question: "How to use Patternful.ai for watermark removal online?",
        answer: "You can use Patternful.ai's AI object remover to remove watermark online as follows: 1. Upload the image you want to edit. 2. Highlight the relevant area where the watermark is present and hit 'Remove'. 3. Your desired edited image will be available for download with watermark removed from the highlighted area.",
    },
];

class ObjectRemoverLanding extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Object Remover</title>
                    <meta
                        name="description"
                        content="Remove objects from photo online. Automatically remove people, text, date stamps, and any other unwanted things to create professional images in a few clicks."
                    />
                    <meta
                        name="keywords"
                        content="Object Remover, Object Remover APP, Object Remover Online, Image Processing, Removing Objects, AI Image Editing, Patternful, Patternful AI"
                    />
                </Helmet>
                <ObjectRemoverBanner />
                <ObjectRemoverHow />
                <FeatureImageLeft
                    image={"/images/object-remover/people.jpg"}
                    title={"Remove People"}
                    description={
                        "Our object remover tool quickly and effortlessly removes people from photos. Whether it's a passerby or a crowd of tourists, it can easily eliminate any unwanted individuals to achieve the ideal photo."
                    }
                />
                <FeatureImageRight
                    image={"/images/object-remover/watermark.jpg"}
                    title={"Remove Text and Watermark"}
                    description={
                        "You can remove watermarks, date stamps, captions, and logos from images without altering the original background. Easily restore the original appearance of your image without any distractions."
                    }
                />
                <FeatureImageLeft
                    image={"/images/object-remover/sale.jpg"}
                    title={"Enhance Real Estate Photos"}
                    description={
                        "Eliminate distractions like unwanted furniture, trash cans, power lines, or plants from your property photos to enhance your real estate marketing. Present your properties in the best possible way to grab the attention of potential buyers and renters."
                    }
                />
                <FeatureImageRight
                    image={"/images/object-remover/confidential.jpg"}
                    title={"Remove Confidential Details"}
                    description={
                        "Before sharing photos publicly, use our object remover to erase any sensitive details like street signs and license plates."
                    }
                />
                <CallToAction
                    title={"Upload Image to Remove Objects"}
                    subtitle={
                        "It has never been this easy to remove objects from photo! Simply mark the unwanted objects and let our object remover do the work. Try it out today to effortlessly and quickly clear away anything that detracts from your photos."
                    }
                    link={"/object-remover/app"}
                    cta={"Get Started Now"}
                />
                <SellFaq data={object_remover_faq_data} />
                <PatternDiscover />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(ObjectRemoverLanding));
